@import '../../styles/settings.scss';

.cart__wrapper {
  max-width: 1400px;
  background: white;
  opacity: 1 !important;
  border-radius: 0.5rem;
  padding: 2rem 1.75rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 20rem $black;
}

  .cart__header {
    display: flex;
    justify-content: space-between;

  p:last-of-type {
    text-decoration: underline;
    opacity: 0.55;
  }
}

.cart__remove {
  cursor: pointer;
}

.cart__products {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem 0;
}

.cart__empty {
  opacity: 0.7;
  display: flex;
  flex-direction: column;
  align-items: center ;

  img {
    width: 3rem;
    margin-top: 0.3rem;
  }
}

.cart__pattern {
  margin-top: 0.3rem;
  transition: width 1s ease;
  opacity: 0.6;
  height: 0.05rem;
  background: black;
  width: 0%;
}

.cart__pattern.on {
  margin-top: 0.3rem;
  transition: width 1s ease;
  opacity: 0.6;
  height: 0.05rem;
  background: black;
  width: 50%;
}

.cart__summary {
  display: flex;
  justify-content: space-between;
}

.cart__total {
  font-weight: 600;
  font-size: 0.938rem;
  opacity: 0.5;
}

.cart__price {
  font-weight: 900;
  font-size: 1.125rem;
}

.cart__bottom {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.cart__btn {
  min-width: 100%;
}
