@import '../../styles/settings.scss';

.navbar {
  width: 100%;

  li {
    list-style: none;
  }
}

.navbar__dropdown {
  display: none;
}

.navbar__wrapper {
  background-color: $black-light;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 10.313rem;
  position: relative;
  z-index: 990;
}

.dropdown__toggle__btn {
  display: none;
}

.navbar__links {
  display: flex;
  gap: 2.125rem;
  padding: 0;
  margin: 0;
  margin-right: 7.5rem;

  a {
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 2px;

    &:hover {
      color: $gray-dark;
    }
  }

  .active {
    color: $orange-dark;

    &:hover {
      color: $orange-dark;
    }
  }

  p {
    text-decoration: none;
    color: $white;
  }
}

.pattern {
  width: 100%;
  height: 0.01rem;
  background-color: $white;
  opacity: 0.2;
}

//  CART
.navbar__cart {
  position: absolute;
  transition: all 0.4s ease-out;
  transform: translateY(-100%);
  right: 8.313rem;
  z-index: 10;
  width: 31%;
}

.cart__container {
  padding: 2rem;
}

.navbar__cart__btn{
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.cart__qty {
  text-align: center;
  margin-top: -0.375rem;
  font-size: 0.688rem;
  width: 1rem;
  height: 1rem;
  color: $white;
  background: $orange-dark;
  border-radius: 50%;
}

.navbar__cart.open {
  transform: translateY(0);
  position: absolute;
  top: 5.7rem;
  right: 8.313rem;
  z-index: 10;
}


//  MEDIA
@media (max-width: 1024px) {

  .navbar__links {
    display: none;
  }

  .dropdown__toggle__btn {
    display: block;
  }

  .navbar__wrapper {
    padding: 2rem 2.5rem;
  }

  .cart__container {
    padding: 1.5rem;
  }

  .navbar__cart {
    width: 54%;
    right: 1rem;
  }

  .navbar__cart.open {
    top: 5.7rem;
    right: 1rem;
  }

  .navbar__dropdown {
    position: absolute;
    transition: all .5s ease-out;
    left: 50%;
    transform: translateY(-150%) translateX(-50%);
    width: 90%;
    z-index: 10;
    border-radius: 0.5rem;
    background-color: $white;
    margin-top: 1.5rem;
    padding: 4rem 1.5rem 1.5rem 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4rem;
    z-index: 10;
    box-shadow: 0 0 20rem $black;

    li {
      background-color: $gray;
      border-radius: 0.5rem;
    }

    li:last-of-type {
      display: none;
    }
  }

  .dropdown__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  .navbar__dropdown__img {
    max-width: 40%;
    margin-top: -3rem;
  }

  .dropdown__text {
    color: $black;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.938rem;
    line-height: 1.25rem;
    letter-spacing: 0.067rem;
  }

  .dropdown__link {
    margin-top: 1.063rem;
    margin-bottom: 1.375rem;
  }

  .navbar__dropdown.open {
    transform: translateY(0) translateX(-50%);
  }

  .dropdown__pattern {
    display: none;
  }
}

@media (max-width: 540px) {

  .navbar__dropdown {
    flex-direction: column;
  }

  .dropdown__box {
    gap: 0;
  }

  .navbar__links {
    display: none;
  }

  .dropdown__toggle__btn {
    display: block;
  }

  .navbar__wrapper {
    padding: 2rem 1.5rem;
  }

  .navbar__cart {
    width: 100%;
    right: 0;
  }

  .navbar__cart.open {
    top: 5.7rem;
    right: 0;
  }
}
