@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

$orange-dark: hsl(22, 65%, 57%);
$orange-light: hsl(21, 94%, 75%);
$gray-dark: hsl(0, 0%, 81%);
$gray: hsl(0, 0%, 95%);
$gray-light: hsl(0, 0%, 98%);
$black: hsl(0, 0%, 0%);
$black-light: hsl(0, 0%, 6%);
$white: hsl(0, 0%, 100%);
$red: hsl(0, 65%, 49%);

$font-text: 'Manrope', sans-serif;
$base-size: 1rem;
