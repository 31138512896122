@import './settings.scss';

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

p {
  margin: 0;
}

.heading__h1 {
  font-weight: 700;
  font-size: 3.5rem;
  line-height: 3.625rem;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
}

.heading__h2 {
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 2.75rem;
  letter-spacing: 0.094rem;
  text-transform: uppercase;
}

.heading__h3 {
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.25rem;
  letter-spacing: 0.072rem;
  text-transform: uppercase;
}

.heading__h4 {
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 2.375rem;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
}

.heading__h5 {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.063rem;
  letter-spacing: 0.106rem;
  text-transform: uppercase;
}

.heading__h6 {
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5rem;
  letter-spacing: 0.081rem;
  text-transform: uppercase;
}

.text__overline {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.188rem;
  letter-spacing: 0.625rem;
  text-transform: uppercase;
  color: $orange-dark;
}

.text__subtitle {
  font-weight: 700;
  font-size: 0.813rem;
  line-height: 1.563rem;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
}

.text__body {
  font-weight: 500;
  font-size: 0.938rem;
  line-height: 1.563rem;
}
