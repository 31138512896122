@import '../../styles/settings.scss';

.footer__wrapper {
  text-align: center;
  background-color: $black-light;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2.25rem;
  margin-top: 12.5rem;
  padding: 0 10.313rem;
  padding-bottom: 3rem;

  h1 {
    color: $white;

    &:hover {
      color: $orange-dark;
    }
  }

  p {
    color: $white;
    opacity: 0.6;
    font-weight: 300 !important;
  }

  li {
    list-style: none;
  }
}

.footer__pattern {
  height: 0.25rem;
  border-radius: 0.05rem;
  width: 10%;
  background-color: $orange-dark;
}

.footer__logo__links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.footer__logo {
  margin-top: 2.438rem;
  max-width: 50%;
}

.footer__links {
  display: flex;
  flex-direction: row;
  gap: 2.125rem;
  padding: 0;
  margin-top: 2.438rem;
}

.footer__info {
  text-align: start;
  width: 31.5rem;
  margin-bottom: 1.25rem;
}

.footer__bottom {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.footer__icons {
  margin: 0;
  margin-top: -10.3rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

@media (max-width: 1024px) {

  .footer__pattern {
    width: 15%;
  }

  .footer__logo__links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .footer__logo {
    max-width: 34%;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .footer__wrapper {
    padding: 2.875rem 2.5rem;
    padding-top: 0;
    text-align: start;
    align-items: flex-start;
    gap: 2rem;
    margin-top: 7.5rem;
  }

  .footer__links {
    flex-direction: row;
    gap: 2.125rem;
    margin: 0;
  }

  .footer__info {
    width: auto;
  }

  .footer__bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .footer__icons {
    margin-top: 0;
  }
}

@media (max-width: 540px) {

  .footer__pattern {
    min-width: 35%;
  }

  .footer__logo__links {
    align-items: center;
  }

  .footer__logo {
    max-width: 50%;
    margin-top: 0;
    margin-bottom: 3rem;
  }

  .footer__wrapper {
    text-align: center;
    align-items: center;
    gap: 3rem;
  }

  .footer__links {
    flex-direction: column;
    gap: 1rem;
  }

  .footer__info {
    text-align: center;
  }

  .footer__bottom {
    flex-direction: column;
    gap: 3rem;
  }
}
