@import '../../styles/settings.scss';

.btn {
  display: inline !important;
  margin: 0;
  padding: 0.938rem 1.844rem 0.938rem 1.969rem;
  text-transform: uppercase;
  font-family: $font-text;
  font-weight: 700;
  font-size: 0.813rem;
  letter-spacing: 0.063rem;
  cursor: pointer;
}

.orange {
  background-color: $orange-dark;
  border: none;
  color: $white;

  &:hover,
  &:focus {
    background-color: $orange-light;
  }
}

.white {
  background-color: $white;
  border: 0.063rem solid $black;
  color: $black;

  &:hover,
  &:focus {
    background-color: $black;
    color: $white;
  }
}

.black {
  background-color: $black;
  border: none;
  color: $white;

  &:hover,
  &:focus {
    background-color: $white;
    color: $black;
  }
}

.transparent {
  background-color: rgba(0, 0, 0, 0);
  border: 0.063rem solid $black;
  color: $black;

  &:hover,
  &:focus {
    background-color: $white;
    color: $black;
  }
}
