@import '../../styles/settings.scss';

.card__header {
  display: flex;
  justify-content: space-between;
  gap: 7.781rem;
}

.card__wrapper {
  display: flex;
  flex-direction: column;
}

.card__goback__box {
  margin-top: 4.938rem;
  margin-bottom: 3.5rem;
}

.card__goback {
  opacity: 0.7;
  cursor: pointer;
  display: inline;
}

.card__image {
  width: 100%;
  background-color: $gray;
  border-radius: 0.5rem;
}

.card__info {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2rem;

  h1 {
    font-size: 40px;
    line-height: 44px;
    width: 70%;
  }

  h2 {
    font-size: 18px;
  }

  .card__info__overline {
    margin-bottom: -1rem;
  }

  .card__info__description {
    opacity: 0.6;
  }
}

.card__orderbox {
  display: flex;
  gap: 1rem;
  margin-top: 0.938rem;
}

.card__middle {
  display: flex;
  gap: 7.813rem;
  margin-top: 10rem;
}

.card__features {
  width: 57%;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  h2 {
    font-size: 32px;
  }

  p {
    opacity: 0.6;
  }
}

.card__inbox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h2 {
    font-size: 32px;
    margin-bottom: 1.5rem;
  }
}

.card__inbox__items__box{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.card__inbox__items{
  display: flex;
  gap: 1.5rem;

  p:first-of-type {
    font-weight: 700;
    opacity: 1;
  }
}

.card__gallery {
  margin-top: 10rem;
  display: flex;
  align-items: center;
  gap: 1.875rem;
}

.card__gallery__left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
}

.card__others {
  margin-top: 10rem;
  margin-bottom: 15rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 4rem;

  h2 {
    font-size: 32px;
  }
}

.card__others__list {
  display: flex;
  gap: 1.875rem;
}

.card__others__items {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.card__others__btn {
  display: inline;
}

.card__info, .card__inbox, .card__others, .card__gallery, .card__image {

  img {
    width: 100%;
    border-radius: 0.5rem;
    object-fit: contain;
  }

  p {
    opacity: 0.7;
  }
}

span {
  color: $orange-dark;
}

@media (max-width: 1023.98px) {

  .card__goback__box {
    margin-top: 2.063rem;
    margin-bottom: 1.5rem;
  }

  .card__header {
    gap: 4.313rem;
  }

  .card__image {
    border-radius: 0.5rem;
    img {
      height: 100%;
    }
  }

  .card__info {
    width: 118%;
    padding: 2.5rem 0;

    h1 {
      font-size: 28px;
      line-height: 32px;
      letter-spacing: 1px;
    }
  }

  .card__orderbox {
    margin-top: 0;
  }

  .card__middle {
    flex-direction: column;
    gap: 7.5rem;
    margin-top: 7.5rem;
  }

  .card__features {
    width: 100%;
  }

  .card__inbox {
    flex-direction: row;
    justify-content: space-between;
  }

  .card__gallery {
    margin-top: 7.5rem;
    gap: 1.125rem;
  }

  .card__gallery__left {
    gap: 1.25rem;
  }

  .card__others {
    margin-top: 7.5rem;
    margin-bottom: 10.75rem;
    gap: 3.5rem;
  }

  .card__others__list {
    gap: 0.688rem;
  }

  .card__others__items {
    h2 {
      font-size: 24px;
    }
  }
}

@media (max-width: 540px) {

  .card__goback__box {
    margin-top: 1rem;
  }

  .card__header {
    flex-direction: column;
    gap: 1rem;
  }

  .card__info {
    width: 100%;
    padding: 0;
    gap: 1.5rem;

    .card__info__overline {
      margin-top: 1rem;
      margin-bottom: -0.5rem;
    }
  }

  .card__orderbox {
    margin-top: 0.5rem;
    gap: 1rem;
  }

  .card__middle {
    margin-top: 5.5rem;
    flex-direction: column;
    gap: 5.5rem;

    h2 {
      font-size: 24px;
    }
  }

  .card__features {
    width: 100%;
    gap: 1.5rem;
  }

  .card__inbox {
    flex-direction: column;
    align-items: flex-start;
  }

  .card__inbox__items{
    gap: 1.5rem;
  }

  .card__gallery {
    margin-top: 5.5rem;
    flex-direction: column;
    gap: 1.25rem;
  }

  .card__gallery__left {
    gap: 1.25rem;
  }

  .card__others {
    margin-top: 7.5rem;
    margin-bottom: 10.75rem;
    text-align: center;
    flex-direction: column;
    gap: 2.5rem;

    h2 {
      font-size: 24px;
    }
  }

  .card__others__list {
    flex-direction: column;
    gap: 3.5rem;
  }

  .card__others__items {
    flex-direction: column;
    gap: 1.5rem;
  }

  .card__info, .card__inbox, .card__others, .card__gallery, .card__image {
    img {
      width: 100%;
      border-radius: 0.5rem;
    }

    p {
      opacity: 0.7;
    }
  }

  span {
    color: $orange-dark;
  }
}
