@import '../../styles/settings.scss';

.dropdown__list {
  display: flex;
  justify-content: center;
  gap: 1.875rem;
  padding: 0;
  margin: 0;

  li {
    text-align: center;
    background-color: $gray;
    border-radius: 0.5rem;
    list-style: none;
  }
}

.dropdown__img {
  max-width: 65%;
  margin-top: -5rem;
}

.box__left {
  margin-top: -0.4rem
}

.box__right {
  margin-top: -4rem;
}

.dropdown__text {
  color: $black;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.563rem;
  letter-spacing: 0.08rem;
  margin-top: -1.5rem;
}

.dropdown__link {
  margin-top: 0.75rem;
  margin-bottom: 1.75rem;
}

//  MEDIA
@media (max-width: 1024px) {

  .dropdown__list {
    gap: 0.625rem;
  }

  .dropdown__img {
    max-width: 70%;
    margin-top: -3rem;
  }
}

@media (max-width: 540px) {

  .dropdown__list {
    flex-direction: column;
    gap: 4rem;
  }

  .dropdown__box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .dropdown__img {
    max-width: 40%;
    margin-top: -3rem;
  }

  .dropdown__text {
    font-size: 0.938rem;
    line-height: 1.25rem;
    letter-spacing: 0.067rem;
    margin-top: -0.5rem;
  }

  .dropdown__link {
    margin-top: 1.063rem;
    margin-bottom: 1.375rem;
  }
}
